<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list
        dense
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          link
          :disabled="!enableNavigation"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template #append>
        <div>
          <v-btn
            block
            @click="logout"
          >
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      :clipped-right="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
      flat
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <span class="hidden-sm-and-down">LaNote Admin Dashboard</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view v-if="isReady" />
    </v-main>
  </v-app>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import { Company, Establishment } from '@/store/models'

export default {
  name: 'AppLayout',
  components: { },
  data: () => ({
    isReady: false,
    drawer: null,
    dialog: null,
    items: [
      { icon: 'mdi-home', text: 'Dashboard', to: { name: 'App-Dashboard' } },
      { icon: 'mdi-contacts', text: 'Users', to: { name: 'App-Users' } },
      { icon: 'mdi-home-group', text: 'Establishments', to: { name: 'App-Establishments' } },
      { icon: 'mdi-food', text: 'Menu Creator', to: { name: 'App-MenuCreator' } }
    ]
  }),
  computed: {
    enableNavigation () {
      return Company.exists()
    }
  },
  async created () {
    await Establishment.api().get()
    const establishment = Establishment.query().first()
    this.setActiveEstablishmentId(establishment?.id)
    this.isReady = true
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('app', ['setActiveEstablishmentId'])
  }
}
</script>
